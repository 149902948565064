/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

export default [
// {
//   header: 'Content',
//   resource: 'auth',
// },
//----------------------------------------
{
  header: "Learning",
  resource: "auth"
},
{
  title: 'Gameplay',
  route: 'learning_gameplay',
  icon: 'GroupIcon',
  resource: 'learning.gameplay',
},
{
  header: 'MANAGEMENT',
  resource: 'auth',
},
{
  title: 'Config',
  route: 'config',
  icon: 'SettingsIcon',
  resource: 'learning.config',
},
{
  title: 'Language',
  route: 'language',
  icon: 'FlagIcon',
  resource: 'learning.language',
},
{
  title: 'User',
  route: 'user',
  icon: 'UserIcon',
  resource: 'learning.user',
},
{
  header: 'ADMIN',
  resource: 'auth',
},
{
  title: 'Admin User',
  route: 'admin-user',
  icon: 'UsersIcon',
  resource: 'admin.admin_user',
},
{
  title: 'Action Records',
  route: 'admin-action-record',
  icon: 'FileTextIcon',
  resource: 'admin.admin_user',
},
]
